import React from 'react'
import { FormProvider } from './hooks/useForm'
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { BotdProvider } from './context/BotdContext'
import { AmpliProvider } from './helpers/amplitude'
import { HistoryListener } from './context/HistoryListener'
import { CookieChoiceProvider } from './hooks/useCookieConsent'
import { SystemStatusProvider } from './context/SystemStatusContext'
import {
  FPJS_PUBLIC_TOKEN,
  FPJS_REGION,
  HOST,
  ROLLBAR_ACCESS_TOKEN,
  GIT_SHA,
  FPJS_USE_TLS,
  IS_VERCEL,
  FPJS_SCRIPT_URL_PATTERN,
} from './constants/env'
import { isBrowser } from './helpers/detector'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { ExperimentProvider } from './helpers/amplitude-experiment'
import { generateRandomString } from './helpers/common'

export type Props = {
  children: React.ReactNode
}

const rollbarConfig = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: (_isUncaught, args, payload) => {
    const error = payload?.body?.trace?.exception?.message
    const message = args[0]?.message

    // Ignore non-actionable minified React errors and specific 6sense errors
    return (
      (error && error.includes('Minified React error')) || (message && message.includes('_6si object on the window'))
    )
  },
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: GIT_SHA,
      },
    },
  },
}

export default function AppProviders({ children }: Props) {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <AppLighthouseProvider>
          <BotdProvider>
            <FormProvider>
              <SystemStatusProvider>
                <CookieChoiceProvider>
                  <HistoryListener>
                    <AmpliProvider>
                      <ExperimentProvider>
                        <React.StrictMode>{children}</React.StrictMode>
                      </ExperimentProvider>
                    </AmpliProvider>
                  </HistoryListener>
                </CookieChoiceProvider>
              </SystemStatusProvider>
            </FormProvider>
          </BotdProvider>
        </AppLighthouseProvider>
      </ErrorBoundary>
    </RollbarProvider>
  )
}

function AppLighthouseProvider({ children }: { children: React.ReactNode }) {
  const isBot = isBrowser()
    ? navigator.userAgent.indexOf('Chrome-Lighthouse') > -1 || navigator.userAgent.indexOf('PageSpeed') > -1
    : true
  const publicApiKey = FPJS_PUBLIC_TOKEN
  const region = FPJS_REGION as FingerprintJS.Region
  const randomPath = generateRandomString(4)
  const scriptUrl = IS_VERCEL
    ? `${HOST}/${randomPath}/?b=load-vercel&v=<version>&a=<apiKey>&l=<loaderVersion>`
    : FPJS_SCRIPT_URL_PATTERN
  if (!isBot) {
    return (
      <FpjsProvider
        loadOptions={{
          apiKey: publicApiKey ?? '',
          region,
          scriptUrlPattern: scriptUrl,
          disableTls: !FPJS_USE_TLS,
        }}
      >
        {children}
      </FpjsProvider>
    )
  } else {
    return <>{children}</>
  }
}
